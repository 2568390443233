import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './components/home/Home';
import Privacy from './components/legal/Privacy';
import Terms from './components/legal/Terms';

const App = () => {
    return (
        <Router>
            <Switch>
            <Route exact path="/">
                <Home />
            </Route>
            <Route path="/privacy">
                <Privacy />
            </Route>
            <Route path="/terms">
                <Terms />
            </Route>
            </Switch>
        </Router>
    );
}

export default App;
