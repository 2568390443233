import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo-text.png'
import appstore from '../../images/appstore-icon.svg';
import './Home.css';
import { Typography, Grid, Divider } from '@material-ui/core';

const Home = () => {
    const appstoreLink = 'https://apps.apple.com/us/app/blaqhub/id1429492238';

    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} className="App-logo" alt="logo" />
                <Typography variant="h6" gutterBottom style={{ margin: 40 }}>
                    We're building the largest directory of<br />
                    black owned businesses and need your help!
                    <Typography variant="subtitle1" gutterBottom style={{ margin: '20px 0px' }}>
                        Download the app today to find and add<br />
                        businesses nationwide or right next door.
                    </Typography>
                    <a href={appstoreLink}><img src={appstore} alt="logo" /></a>
                </Typography>
                <Grid container justify="center" spacing={3} style={{ color: 'green', marginTop: 30 }}>
                    <Grid item>
                        <Link to="/privacy" style={{ textDecoration: 'none' }}>
                            <Typography variant="body2" gutterBottom>Privacy</Typography>
                        </Link>
                    </Grid>
                    <Divider orientation="vertical" flexItem style={{ margin: 10 }} />
                    <Grid item>
                        <Link to="/terms" style={{ textDecoration: 'none' }}>
                            <Typography variant="body2" gutterBottom>Terms</Typography>
                        </Link>
                    </Grid>
                </Grid>
            </header>
        </div>
    );
}

export default Home;
